import { useState, useEffect } from "react";
import { APIURL } from "../../utils/utilKumho";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import moment from "moment";
import Sgv from "../../assets/Sgv";
import axios from "axios";
import Modals from "../board/Modals";
import { Link, useHistory } from "react-router-dom";
import Loading from "../../Layout/Loading";

function AdminList({ pboardidx }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const onClick = (boardidx, boardseq) => {
    // BOARDVIEW/IDX/SEQ로 넘어가면됨.
    history.push({
      pathname: `/BoardViewAD/${boardidx}/${boardseq}`,
    });
  };

  const onOver = (e, param) => {
    document.getElementById(param + "over").style.display = "";
    document.getElementById(param + "out").style.display = "none";
  };

  const onLeave = (e, param) => {
    document.getElementById(param + "over").style.display = "none";
    document.getElementById(param + "out").style.display = "";
  };
  const onDel = (idx, seq) => {
    if (window.confirm("삭제하시겠습니까?")) {
      //alert("삭제");
      delData(idx, seq);
    }
  };
  async function getdata() {
    try {
      const response = await axios.get(APIURL("getBoard"), {
        params: {
          type: "ADList",
          boardidx: pboardidx,
        },
      });

      setList(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  async function delData(boardidx, boardseq) {
    try {
      const res = await axios.post(APIURL("PostBoard2"), {
        type: "DELETE",
        boardidx: `${boardidx}`,
        boardseq: `${boardseq}`,
        title: "1",
        subtitle: "1",
        content: "1",
        writer: "1",
        tempYn: "1",
      });
      if (res.status === 201) {
        //console.log("해당하는 행을 지움.");
        setList((prev) =>
          prev.filter(
            (prev) => prev.board_idx !== boardidx || prev.board_seq !== boardseq
          )
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getdata();
  }, [pboardidx]);

  return (
    <div>
      {loading ? (
        <Loading type="admin" />
      ) : (
        <table className="table table-striped">
          <thead>
            <tr style={{ top: -16 }} className="sticky-sm-top">
              <th className="col-6" scope="col">
                제목
              </th>
              <th className="col-1" scope="col">
                임시저장 상태
              </th>
              <th className="col-1" scope="col">
                업로드날짜
              </th>
              <th className="col-1" scope="col">
                발행예약일
              </th>
              <th className="col-1" scope="col">
                조회수
              </th>
              <th className="col-1" scope="col">
                댓글
              </th>
            </tr>
          </thead>
          <tbody>
            {list.length === 0 ? (
              <tr>
                <td colSpan="6" className="text-center">
                  게시글이 없습니다.
                </td>
              </tr>
            ) : (
              list.map((e) => (
                <tr
                  id={e.board_idx + e.board_seq}
                  key={e.board_idx + e.board_seq}
                  onMouseOver={(t) => onOver(t, e.board_idx + e.board_seq)}
                  onMouseLeave={(t) => onLeave(t, e.board_idx + e.board_seq)}
                >
                  <td
                    className="hover-underline "
                    onClick={() => onClick(e.board_idx, e.board_seq)}
                  >
                    <div style={{ float: "left" }}>
                      <img
                        style={{ width: "6vw", height: "4vw" }}
                        className="col-12 me-3"
                        src={e.ThumbNail_img}
                      />
                    </div>
                    <div>
                      <span>{e.title}</span>
                      <br />
                      <div
                        id={e.board_idx + e.board_seq + "over"}
                        style={{ display: "none" }}
                        className="mt-1"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="d-flex ">
                          <Modals
                            boardidx={e.board_idx}
                            boardseq={e.board_seq}
                            modify={"Y"}
                            cls={"btn-white"}
                            parenfunc={(e) => getdata()}
                          />
                          <div>
                            <button
                              type="button"
                              className="btn btn-white"
                              onClick={() => onDel(e.board_idx, e.board_seq)}
                            >
                              <Sgv type="trash" />
                            </button>
                          </div>
                        </div>
                      </div>

                      <span
                        id={e.board_idx + e.board_seq + "out"}
                        className="text-secondary"
                        style={{ fontSize: 0.9 + "rem", display: "" }}
                      >
                        {e.subtitle}
                      </span>
                    </div>
                  </td>
                  <td>{e.Temp_YN}</td>
                  <td>{moment.utc(e.regdate).format("YYYY.MM.DD")}</td>
                  <td>
                    {e.RESERVEDATE &&
                      moment.utc(e.RESERVEDATE).format("YYYY.MM.DD")}
                  </td>
                  <td>{e.READ_CNT}</td>
                  <td>{e.CNT_CMT} </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default AdminList;

import axios from "axios";
import { APIURL } from "../../utils/utilKumho";
import { useState, useEffect } from "react";
import Loading from "../../Layout/Loading";
function AdminCerti() {
  const [emplist, setEmplist] = useState([]);
  const [certilist, setCertilist] = useState([]);
  const [chklist, setChkList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  async function getData() {
    try {
      const response = await axios.get(APIURL("getBoard"), {
        params: {
          type: "Allemp",
        },
      });

      //console.log(response.data);
      setEmplist(response.data);
    } catch (error) {
      console.error(error);
    }
  }
  async function getCertidata() {
    try {
      const response = await axios.get(APIURL("getBoard"), {
        params: {
          type: "CertEmp",
        },
      });

      //console.log(response.data);
      setCertilist(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  async function postData(type, empno, empnm, deptnm) {
    try {
      await axios.post(APIURL("PostComm"), {
        type: type,
        param: empno,
      });
      //console.log(response.status);
    } catch (error) {
      console.error(error);
    } finally {
      // 권한 추가
      if (type === "AuthIn") {
        setEmplist((prev) => prev.filter((data) => data.EMPNO.trim() != empno)); //사원리스트삭제
        setCertilist((prev) => [
          ...prev,
          { EMPNO: empno, EMPNM: empnm, DEPTNM: deptnm },
        ]); //권한 리스트 추가
      } else if (type === "AuthOut") {
        //권한 삭제
        setCertilist((prev) =>
          prev.filter((data) => data.EMPNO.trim() != empno)
        ); //권한 리스트삭제
        setEmplist((prev) => [
          ...prev,
          { EMPNO: empno, EMPNM: empnm, DEPTNM: deptnm },
        ]); //사원 리스트 추가
      }
    }
  }

  //console.log(emplist);

  useEffect(() => {
    const fetchData = async () => {
      await getData();
      await getCertidata();
      setLoading2(false);
    };

    fetchData();

    return () => {};
  }, []);

  const onClick2 = async (text, e) => {
    var chk;

    if (text === "AuthIn") {
      chk = document.querySelectorAll(".Lempchk:checked");
    } else if (text === "AuthOut") {
      chk = document.querySelectorAll(".Rempchk:checked");
    }
    var empinfo = Array.from(chk).map((d) => ({
      EMPNO: d.value.split(",")[0].trim(),
      EMPNM: d.value.split(",")[1].trim(),
      DEPTNM: d.value.split(",")[2].trim(),
    })); //배열에 json으로 Empinfo를 담음.

    //실제 데이터 POST

    setLoading(true);
    if (empinfo.length > 0) {
      for (var i = 0; empinfo.length > i; i++) {
        await postData(
          text,
          empinfo[i].EMPNO,
          empinfo[i].EMPNM,
          empinfo[i].DEPTNM
        ); //console.log(empinfo[i]);
      }
    }
    setLoading(false);
    //변수값 갱신
    if (text === "AuthIn") {
      chk = document.querySelectorAll(".Lempchk:checked");
    } else if (text === "AuthOut") {
      chk = document.querySelectorAll(".Rempchk:checked");
    }
    //chk all off, table tr color off
    chk.forEach((data) => {
      console.log(data);
      data.checked = false;
      document.getElementById("TR" + data.id).setAttribute("class", "");
    });

    //화면상 사라지게(statue 리스트에서 해당 데이터 삭제)
    //if (empinfo.length > 0) {
    //  if (text === "추가") {
    //    setEmplist(
    //      (prev) => prev.filter((data) => !empinfo.includes(data.EMPNO.trim())) // emplist에서 제외
    //    );
    //  } else {
    //    setCertilist(
    //      (prev) => prev.filter((data) => !empinfo.includes(data.EMPNO.trim())) // Certilist에서 제외
    //    );
    //  }
    //}
    // 위 코드도 나중에 활용.
  };

  const onClick = (id) => {
    const chk = document.getElementById(id);
    const tr = document.getElementById("TR" + id);
    chk.checked = !chk.checked;

    if (chk.checked) tr.setAttribute("class", "table-secondary"); //tr color on.
    else tr.setAttribute("class", ""); //tr color off.
  };
  function LoadingTable() {
    return <div>Loading...</div>;
  }
  function DataTable() {
    return (
      <>
        <div className="border-bottom mb-5" style={{ height: "5vh" }}>
          <h3>관리자 권한 부여</h3>
        </div>
        <div className="d-flex">
          <div
            id="OffCerti"
            className="col-4 overflow-auto"
            style={{ height: "85vh" }}
          >
            <table className="table table-striped text-center">
              <thead>
                <tr
                  style={{ top: 0 }}
                  className="sticky-sm-top table-secondary"
                >
                  <th className="col-1" scope="col">
                    체크
                  </th>
                  <th className="col-3" scope="col">
                    이름
                  </th>
                  <th className="col-3" scope="col">
                    부서
                  </th>
                </tr>
              </thead>

              <tbody>
                {emplist.map((emp, idx) => (
                  <tr
                    key={idx}
                    id={"TRL" + idx}
                    style={{ cursor: "pointer" }}
                    onClick={() => onClick("L" + idx)}
                  >
                    <td>
                      <input
                        id={"L" + idx}
                        type="checkbox"
                        className="Lempchk"
                        value={[emp.EMPNO, emp.EMPNM, emp.DEPTNM]}
                        onClick={() => onClick("L" + idx)}
                      />
                    </td>
                    <td>{emp.EMPNM}</td>
                    <td>{emp.DEPTNM}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            id="Cenbtn"
            className="col-2 text-center d-flex flex-column align-items-center justify-content-center"
            style={{ height: "70vh" }}
          >
            <button
              type="button"
              className="btn btn-success mb-3"
              onClick={(e) => onClick2("AuthIn", e)}
              disabled={loading}
            >
              {loading ? (
                "처리중..."
              ) : (
                <>
                  ▶
                  <br />
                  추가
                </>
              )}
            </button>
            {loading && ( // 로딩 중일 때 스피너 표시
              <div
                className="spinner-border text-primary m-1"
                role="status"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            <button
              type="button"
              className="btn btn-danger mt-3"
              onClick={(e) => onClick2("AuthOut", e)}
              disabled={loading}
            >
              {loading ? (
                "처리중..."
              ) : (
                <>
                  ◀
                  <br />
                  제거
                </>
              )}
            </button>
          </div>
          <div id="OnCerti" className="col-4">
            <table className="table table-striped text-center">
              <thead>
                <tr
                  style={{ top: 0 }}
                  className="sticky-sm-top table-secondary"
                >
                  <th className="col-1" scope="col">
                    체크
                  </th>
                  <th className="col-3" scope="col">
                    이름
                  </th>
                  <th className="col-3" scope="col">
                    부서
                  </th>
                </tr>
              </thead>

              <tbody>
                {certilist.map((emp, idx) => (
                  <tr
                    key={idx}
                    id={"TRR" + idx}
                    style={{ cursor: "pointer" }}
                    onClick={() => onClick("R" + idx)}
                  >
                    <td>
                      <input
                        key={"R" + idx}
                        id={"R" + idx}
                        className="Rempchk"
                        type="checkbox"
                        value={[emp.EMPNO, emp.EMPNM, emp.DEPTNM]}
                        onClick={() => onClick("R" + idx)}
                      />
                    </td>
                    <td>{emp.EMPNM}</td>
                    <td>{emp.DEPTNM}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }

  return <div>{loading2 ? <Loading type="Certi" /> : <DataTable />}</div>;
}

export default AdminCerti;

import { useState, useEffect, useRef } from "react";
import { ReactComponent as Smile } from "../../assets/smile.svg";
import { useCookie, APIURL } from "../../utils/utilKumho.js";
import { Overlay, Popover, Button } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import EmojiPicker from "emoji-picker-react";

function CommentWrite({
  ReftCountup,
  boardidx,
  boardseq,
  cmt_idx,
  comment,
  type,
}) {
  const [Comment, setComment] = useState("");
  const [Cmtwriter, setCmtwriter] = useState("");
  const [CmtwriterNo, setCmtwriterNo] = useState("");

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  //console.log(ref);
  const Empnm = useCookie();

  const setEmoji = (event) => {
    const emoji = event.emoji;
    setComment((preComment) => preComment + emoji);
  };

  const onChange = (e) => {
    setComment(e.target.value);
    setShow(false);
  };

  const onActiveEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSubmit();
    }
  };

  const onSubmit = async (event) => {
    if (Comment === "") {
      alert("리플을 적으세요");
      return;
    }
    await postData(type);
    setComment("");
    if (ReftCountup) {
      //리렌더링 부모함수 호출
      ReftCountup();
    }
  };

  const onClick = (event) => {
    setShow(false);
    onSubmit();
  };

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  async function postData(type) {
    try {
      const nowTime = moment().format("YYYY-MM-DD HH:mm:ss");
      var response;
      if (type === "insert") {
        response = await axios.post(APIURL("setCmm"), {
          board_idx: `${boardidx}`,
          board_seq: `${boardseq}`,
          comment: `${Comment}`,
          regdate: `${nowTime}`,
          cmtWriter: `${Cmtwriter}`,
          cmtWriterNo: `${CmtwriterNo}`,
        });
      } else if (type === "update") {
        //console.log(cmt_idx + "전송시");
        response = await axios.put(
          APIURL("putCmm"),
          {
            board_idx: `${boardidx}`,
            board_seq: `${boardseq}`,
            cmt_idx: `${cmt_idx}`,
            comment: `${Comment}`,
            cmtWriter: `${Cmtwriter}`,
            cmtWriterNo: `${CmtwriterNo}`,
          },
          { params: { cmt_idx: cmt_idx } }
        );
      }
      if (response.status === 201)
        switch (type) {
          case "update":
            console.log("댓글 수정 완료");
            break;
          case "insert":
            console.log("댓글 등록 완료");
            break;
        }
    } catch (error) {
      console.error(error);
      alert("에러!" + error);
    }
  }

  useEffect(() => {
    setCmtwriter(Empnm[1]);
    setCmtwriterNo([Empnm[0]]);
    if (type === "update") setComment(comment);
  }, [Empnm]);

  // Popover 외부 클릭 감지
  return (
    <div ref={ref}>
      <form onSubmit={onSubmit} className="row g-3">
        <div className="col-md-2 d-flex align-items-center mt-4">
          <label htmlFor="writer" className="form-label"></label>
          <input
            type="text"
            className="form-control"
            id="writer"
            value={Cmtwriter}
            placeholder=""
            disabled
          />
        </div>
        <div className="col-md-8 d-flex align-items-center mt-4">
          <input
            type="text"
            className="form-control"
            id="comment"
            value={Comment}
            onChange={onChange}
            onKeyDown={onActiveEnter}
            placeholder="댓글을 입력해주세요."
          />
          <div className="ms-3">
            <Smile style={{ cursor: "pointer" }} onClick={handleClick} />
          </div>
        </div>
        <div className="col-md-2 d-flex align-items-center mt-4">
          <button
            type="button"
            onClick={onClick}
            className={`btn ${
              type === "insert" ? "btn-danger" : "btn-success"
            } w-100`}
          >
            {type === "insert" ? "댓글등록" : "수정"}
          </button>
        </div>
      </form>
      <Overlay
        show={show}
        target={target}
        placement="top"
        container={ref}
        containerPadding={5}
      >
        <Popover id="popover-contained">
          <Popover.Body className="p-0">
            <EmojiPicker
              onEmojiClick={setEmoji}
              emojiStyle="native"
              emojiVersion="5.0"
              width="100"
              searchDisabled={true}
              autoFocusSearch={false}
            />
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
}

export default CommentWrite;

import CheckLogin from "../utils/CheckLogin.js";
import NavBar from "./NavBar.js";
import axios from "axios";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Sgv from "../assets/Sgv.js";
import "../assets/CSS/View.css";
import { APIURL } from "../utils/utilKumho.js";
import Loading from "../Layout/Loading.js";

function List({ type, boardidx, mainYn }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const param = useParams();
  console.log(list);
  async function getData() {
    try {
      const response = await axios.get(APIURL("getBoard"), {
        params: {
          type: "BDList",
          boardidx: param.idx,
        },
      });

      setList(response.data);
      //setList();
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getData();
  }, [param]);
  function Card({
    url,
    subtitle,
    title,
    date,
    boardidx,
    boardseq,
    newpost,
    cnt,
  }) {
    return (
      <div className="col mb-5">
        <div className="card-body">
          <Link to={`/BoardView/${boardidx}/${boardseq}`}>
            <img
              //style={{ width: "25vw", height: "12vw" }}
              className="col-12 hover-underline"
              src={url}
            />
          </Link>
          <p className="card-text text-dark mt-3">
            {`${subtitle}`}
            {newpost ? (
              <span class="ms-1 badge rounded-pill text-bg-danger">
                newpost
              </span>
            ) : null}
          </p>
          <Link
            className="text-decoration-none d-flex align-items-center"
            to={`/BoardView/${boardidx}/${boardseq}`}
          >
            <span className="card-text text-dark fw-bold fs-3 hover-underline">
              {title}
            </span>
            <span className="badge rounded-pill text-bg-secondary fs-6 ms-1">
              {cnt}
            </span>
          </Link>
          <p
            style={{ fontSize: "0.85em" }}
            className="card-text text-secondary"
          >
            {date}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="mt-5">
      <CheckLogin />
      <header>{mainYn === "Y" ? "" : <NavBar />}</header>
      <main>
        <div className="pt-3"></div>

        <div id="containerMain" className="pt-3 lt-container">
          {loading ? (
            <Loading type="list" />
          ) : list.length === 0 ? (
            <div className="col-12 mt-5 text-center">
              <p className="fs-1 text-secondary">
                <Sgv type="sad" />
                등록된 글이 없습니다.
              </p>
            </div>
          ) : (
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-5">
              {list.map((lst) => (
                <Card
                  url={lst.ThumbNail_img}
                  subtitle={lst.subtitle}
                  title={lst.title}
                  date={moment.utc(lst.regdate).format("YYYY.MM.DD")}
                  key={lst.board_idx + lst.board_seq}
                  boardidx={lst.board_idx}
                  boardseq={lst.board_seq}
                  cnt={lst.CNT}
                  newpost={
                    moment.utc(lst.regdate).format("YYYYMMDD") >=
                    moment().add(-3, "day").format("YYYYMMDD")
                      ? true
                      : false
                  }
                />
              ))}
            </div>
          )}
        </div>
      </main>
      <footer></footer>
    </div>
  );
}

export default List;

import { useState, useEffect, useRef } from "react";
import CmmView from "./CmmView.js";
import CommentWrite from "./CommentWrite.js";

function CommentBox({ boardidx, boardseq }) {
  const [refT, setRefT] = useState(0);
  //부모 함수 만들고
  // 자식에서 부모함수 호출후 실행.
  function ReftCountup() {
    setRefT((prevRefT) => prevRefT + 1);
  }

  return (
    <div className="container my-4">
      <h4 className="pb-2 mb-4 border-bottom">댓글</h4>
      <div className="row">
        <div className="col-12">
          <CmmView board_idx={boardidx} board_seq={boardseq} refT={refT} />
        </div>
      </div>
      <CommentWrite
        ReftCountup={ReftCountup}
        boardidx={boardidx}
        boardseq={boardseq}
        type={"insert"}
      />
    </div>
  );
}

export default CommentBox;

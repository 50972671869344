import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import CommentWrite from "./CommentWrite.js";
import { useCookie, APIURL, EscEvent } from "../../utils/utilKumho";

function CmmView({ board_idx, board_seq, refT }) {
  const [Cmm, setCmm] = useState([]);
  const [Modify_CmtId, setModify_CmtId] = useState(null);
  const empno = useCookie();
  const [Reft2, setReft2] = useState(0);
  EscEvent(() => setModify_CmtId(null)); //ESC 이벤트  Hook

  useEffect(() => {
    getData();
  }, [refT, board_seq, Reft2]);

  const reft_UP = (reft) => {
    setModify_CmtId(null);
    setReft2((prev) => prev + 1);
  };

  async function getData() {
    try {
      const response = await axios.get(APIURL("getCmm"), {
        params: {
          boardidx: board_idx,
          boardseq: board_seq,
        },
      });

      setCmm(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  function ChkDel(cmt_idx) {
    var response = window.confirm("삭제하겠습니까?");
    if (response) handleDelete(cmt_idx);
  }

  async function handleDelete(cmt_idx) {
    try {
      await axios.delete(APIURL("delCmm"), {
        params: {
          board_idx: board_idx,
          board_seq: board_seq,
          cmt_idx: cmt_idx,
        },
      });
      setCmm((prevCmm) => prevCmm.filter((cmt) => cmt.cmt_idx !== cmt_idx));
    } catch (error) {
      console.error(error);
    }
  }

  function CmtModify(cmt_idx) {
    //alert(cmt_idx);
    setModify_CmtId(cmt_idx);
    //해당칸만 변경?
  }

  return (
    <div className="container mt-3">
      {Cmm.length === 0 ? (
        <p className="text-center">등록된 댓글이 없습니다.</p>
      ) : (
        Cmm.map((cmt) => (
          <div
            className="d-flex justify-content-between align-items-center border-bottom py-2"
            key={cmt.cmt_idx}
          >
            {Modify_CmtId == cmt.cmt_idx ? (
              <div className="d-flex flex-column col-12 pe-1">
                <CommentWrite
                  boardidx={board_idx}
                  boardseq={board_seq}
                  cmt_idx={cmt.cmt_idx}
                  type={"update"}
                  comment={cmt.comment}
                  ReftCountup={reft_UP}
                />
              </div>
            ) : (
              <>
                <div className="d-flex flex-column col-10 pe-1">
                  <span className="fw-bold" style={{ fontSize: "1rem" }}>
                    {cmt.cmtWriter}
                  </span>
                  <span id={cmt.cmt_idx} style={{ fontSize: "0.875rem" }}>
                    {cmt.comment}
                  </span>
                </div>
                <div className="col-1">
                  {cmt.cmtWriterNo === empno[0] ? (
                    <div>
                      <a
                        className="text-muted me-3"
                        style={{
                          fontSize: "0.75rem",
                          color: "#6c757d",
                          cursor: "pointer",
                        }}
                        onClick={() => CmtModify(cmt.cmt_idx)}
                      >
                        수정
                      </a>
                      <a
                        className="text-muted"
                        style={{
                          fontSize: "0.75rem",
                          color: "#6c757d",
                          cursor: "pointer",
                        }}
                        onClick={() => ChkDel(cmt.cmt_idx)}
                      >
                        삭제
                      </a>
                    </div>
                  ) : (
                    <span
                      className="text-muted"
                      style={{ fontSize: "0.75rem", color: "#6c757d" }}
                    ></span>
                  )}
                </div>
                <div className="col-1">
                  <span
                    className="text-muted"
                    style={{ fontSize: "0.75rem", color: "#6c757d" }}
                  >
                    {cmt.regdate
                      ? moment.utc(cmt.regdate).format("YYYY-MM-DD")
                      : ""}
                  </span>
                </div>
              </>
            )}
          </div>
        ))
      )}
    </div>
  );
}

export default CmmView;

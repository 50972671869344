function Loading({ type = "Certi" }) {
  function PlaceHolder({ cls = "col-8 bg-secondary", height = "3vh" }) {
    return (
      <div className="placeholder-glow d-flex">
        <div
          className={`m-1 placeholder rounded ${cls}`}
          style={{ height: height }}
        ></div>
      </div>
    );
  }
  function AdminBoardType() {
    const rows = Array.from({ length: 7 });
    return (
      <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
        {rows.map((e, idx) => (
          <li key={idx}>
            <PlaceHolder cls="bg-secondary" height="2vh" />
          </li>
        ))}
      </ul>
    );
  }
  function Admintable() {
    const rows = Array.from({ length: 10 });
    return (
      <table className="table table-stripezd">
        <thead>
          <tr style={{ top: -16 }} className="sticky-sm-top">
            <th className="col-6" scope="col">
              제목
            </th>
            <th className="col-1" scope="col">
              임시저장 상태
            </th>
            <th className="col-1" scope="col">
              업로드날짜
            </th>
            <th className="col-1" scope="col">
              발행예약일
            </th>
            <th className="col-1" scope="col">
              조회수
            </th>
            <th className="col-1" scope="col">
              댓글
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((e, idx) => (
            <tr key={idx}>
              <td>
                <div className="placeholder-glow d-flex">
                  <div
                    className="m-1 placeholder col-2 rounded bg-secondary"
                    style={{ width: "6vw", height: "8vh" }}
                  ></div>
                  <div className="col-9  p-1">
                    <div
                      className="m-1 placeholder col-12 rounded bg-secondary"
                      style={{ height: "3vh" }}
                    ></div>
                    <div
                      className="m-1 placeholder col-6 rounded bg-secondary"
                      style={{ height: "3vh" }}
                    ></div>
                  </div>
                </div>
              </td>
              <td>
                <PlaceHolder cls="bg-secondary" />
              </td>
              <td>
                <PlaceHolder cls="bg-secondary" />
              </td>
              <td>
                <PlaceHolder cls="bg-secondary" />
              </td>
              <td>
                <PlaceHolder cls="bg-secondary" />
              </td>
              <td>
                <PlaceHolder cls="bg-secondary" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  function Main() {
    return (
      <div>
        <PlaceHolder cls="bg-secondary col-12" height="44rem" />
      </div>
    );
  }
  function List() {
    const rows = Array.from({ length: 12 });
    function Card() {
      return (
        <div className="col mb-5">
          <div className="card-body">
            <PlaceHolder cls="bg-secondary col-12" height="20vh" />

            <p className="card-text text-dark fw-bold fs-3 hover-underline">
              <PlaceHolder cls="bg-secondary col-12" height="" />
            </p>
            <p
              style={{ fontSize: "0.85em" }}
              className="card-text text-secondary"
            >
              <PlaceHolder cls="bg-secondary col-6" height="" />
            </p>
          </div>
        </div>
      );
    }
    return (
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-5">
        {rows.map((e, idx) => (
          <Card key={idx} />
        ))}
      </div>
    );
  }

  function AdminList() {
    const rows = Array.from({ length: 12 });
    //console.log(rows.length);
    function Card() {
      return (
        <>
          <PlaceHolder cls="bg-secondary col-12" height="4.5vh" />
        </>
      );
    }
    return (
      <div>
        <div className="border-bottom mb-5 col-3" style={{ height: "5vh" }}>
          <Card />
        </div>
        <div className="d-flex">
          <div
            id="OffCerti"
            className="col-4 overflow-auto"
            style={{ height: "85vh" }}
          >
            <table className="table table-striped text-center">
              <thead>
                <tr
                  style={{ top: 0 }}
                  className="sticky-sm-top table-secondary"
                >
                  <th className="col-1" scope="col">
                    <Card />
                  </th>
                  <th className="col-3" scope="col">
                    <Card />
                  </th>
                  <th className="col-3" scope="col">
                    <Card />
                  </th>
                </tr>
              </thead>

              <tbody>
                {rows.map((e, idx) => (
                  <tr key={idx}>
                    <td colSpan={3} className="border-0 p-1 pe-2">
                      <Card />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            id="Cenbtn"
            className="col-2 text-center d-flex flex-column align-items-center justify-content-center"
            style={{ height: "70vh" }}
          >
            <div className="col-3">
              <Card />

              <Card />
            </div>
          </div>
          <div
            id="OnCerti"
            className="col-4 overflow-auto"
            style={{ height: "85vh" }}
          >
            <table className="table table-striped text-center">
              <thead>
                <tr
                  style={{ top: 0 }}
                  className="sticky-sm-top table-secondary"
                >
                  <th className="col-1" scope="col">
                    <Card />
                  </th>
                  <th className="col-3" scope="col">
                    <Card />
                  </th>
                  <th className="col-3" scope="col">
                    <Card />
                  </th>
                </tr>
              </thead>

              <tbody>
                {rows.map((e, idx) => (
                  <tr key={idx}>
                    <td colSpan={3} className="border-0 p-1 pe-2">
                      <Card />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
  //return
  switch (type) {
    case "admin":
      return (
        <div>
          <div>
            <Admintable />
          </div>
        </div>
      );
      break;
    case "adminType":
      return (
        <div>
          <AdminBoardType />
        </div>
      );
      break;
    case "main":
      return (
        <div>
          <Main />
        </div>
      );
      break;
    case "list":
      return (
        <div>
          <List />
        </div>
      );
      break;
    case "Certi":
      return (
        <div>
          <AdminList />
        </div>
      );
  }
}

export default Loading;

import CheckLogin from "../utils/CheckLogin.js";
import NavBar from "./NavBar.js";
import axios from "axios";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Sgv from "../assets/Sgv.js";
import "../assets/CSS/View.css";
import { APIURL } from "../utils/utilKumho.js";
import Loading from "../Layout/Loading.js";

function OLDList({ type, boardidx, mainYn }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(1);
  const param = useParams();

  async function getData() {
    try {
      const response = await axios.get(APIURL("getBoard"), {
        params: {
          type: "OLDList",
          boardidx: "1002",
          param: offset,
        },
      });

      setList((prev) => [...prev, ...response.data]);
      //setList();
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getData();
  }, [param, offset]);

  function Card({ url, subtitle, title, date, boardidx, boardseq, newpost }) {
    return (
      <div className="col mb-5">
        <div className="card-body">
          <Link to={`/OLDPhotoView/1002/${boardseq}`}>
            <img
              //style={{ width: "25vw", height: "12vw" }}
              className="col-12 hover-underline"
              src={url}
            />
          </Link>
          <p className="card-text text-dark mt-3">
            {`${subtitle}`}
            {newpost ? (
              <span class="ms-1 badge rounded-pill text-bg-danger">
                newpost
              </span>
            ) : null}
          </p>
          <Link
            className="text-decoration-none"
            to={`/OLDPhotoView/1002/${boardseq}`}
          >
            <p className="card-text text-dark fw-bold fs-3 hover-underline">
              {title}
            </p>
          </Link>
          <p
            style={{ fontSize: "0.85em" }}
            className="card-text text-secondary"
          >
            {date}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="mt-5">
      <CheckLogin />
      <header>{mainYn === "Y" ? "" : <NavBar />}</header>
      <main>
        <div className="pt-3"></div>

        <div id="containerMain" className="pt-3 lt-container">
          {loading ? (
            <Loading type="list" />
          ) : list.length === 0 ? (
            <div className="col-12 mt-5 text-center">
              <p className="fs-1 text-secondary">
                <Sgv type="sad" />
                등록된 글이 없습니다.
              </p>
            </div>
          ) : (
            <>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-5">
                {list.map((lst) => (
                  <Card
                    url={
                      "https://www.kumholamp.info/Datafile/board/1002/" +
                      lst.File_TimeName
                    }
                    subtitle=""
                    title={lst.TITLE}
                    date={moment.utc(lst.WRITE_DAY).format("YYYY.MM.DD")}
                    key={lst.SEQ}
                    boardidx={lst.board_idx}
                    boardseq={lst.SEQ}
                    newpost={
                      moment.utc(lst.WRITE_DAY).format("YYYYMMDD") >=
                      moment().add(-3, "day").format("YYYYMMDD")
                        ? true
                        : false
                    }
                  />
                ))}
              </div>
              <div className="col-12 text-center">
                <button
                  type="button"
                  className="btn btn-secondary col-6 m-5"
                  onClick={() => {
                    setOffset((prev) => prev + 1);
                  }}
                >
                  더보기
                </button>
              </div>
            </>
          )}
        </div>
      </main>
      <footer></footer>
    </div>
  );
}

export default OLDList;

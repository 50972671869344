import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import ThemeMode from "../../component/ThemeMode";
import AdminList from "./AdminList";
import Modals from "../board/Modals";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../utils/utilKumho";
import Loading from "../../Layout/Loading";
import AdminCerti from "./AdminCerti";

function Admin() {
  const [boardidx, setboardidx] = useState("");
  const [BDType, setBDType] = useState([]);
  const [key, setkey] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pageType, setpageType] = useState("");
  async function getBDType() {
    try {
      const res = await axios.get(APIURL("getBoard"), {
        params: {
          type: "BDType",
        },
      });
      setBDType(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const onClick = (type, board_idx) => {
    //console.log(type !== pageType);
    if (type !== pageType) setpageType(type);
    setboardidx(board_idx);
  };

  useEffect(() => {
    getBDType();
  }, []);

  function RightPage({ type }) {
    switch (type) {
      case "List":
        return <AdminList pboardidx={boardidx} key={key} />;
        break;
      case "Certi":
        return <AdminCerti />;
        break;
      default:
        return <AdminList pboardidx={boardidx} key={key} />;
        break;
    }
  }

  return (
    <div>
      <ThemeMode />
      <main className="d-flex flex-nowrap adminmain">
        <div
          className="leftbar p-3 me-3"
          style={{
            width: 200 + "px",
            height: 100 + `vh`,
          }}
        >
          <div className="d-flex flex-nowrap align-items-center pb-3 mb-3 link-body-emphasis text-decoration-none border-bottom">
            <Link to="/main">
              <img
                src="/kheinstar.png"
                className="m-1"
                width="40px"
                height="40px"
                placeholder="InstargramIMG"
                alt="KHE"
              />
            </Link>
            <Link to="/main">
              <span className="fs-5 fw-semibold badge pt-2 pb-2  link-underline-opacity-0 link-body-emphasis link-offset-2 link-underline-opacity-25">
                와글와글
              </span>
            </Link>
          </div>
          <div className="d-flex align-items-center pb-3 mb-3 link-body-emphasis text-decoration-none border-bottom">
            <span className="fs-5 fw-semibold ">관리자페이지</span>
          </div>

          <ul className="list-unstyled ps-0">
            <li className="mb-1">
              <button
                className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#home-collapse"
                aria-expanded="true"
              >
                게시판
              </button>
              <div className="collapse show" id="home-collapse">
                {loading ? (
                  <Loading type="adminType" />
                ) : (
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li>
                      <a
                        href="#"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                        onClick={() => onClick("List")}
                      >
                        전체
                      </a>
                    </li>
                    {BDType.map((e) => (
                      <li>
                        <a
                          href="#"
                          className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                          onClick={() => onClick("List", e.board_idx)}
                        >
                          {e.board_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </li>
            <li className="border-top my-3"></li>
            <li className="mb-1">
              <button
                className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#account-collapse"
                aria-expanded="false"
              >
                관리
              </button>
              <div className="collapse" id="account-collapse">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small ">
                  <li>
                    <a
                      href="#"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      onClick={() => onClick("Certi")}
                    >
                      관리자 권한 부여
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      style={{
                        pointer_events: "none",
                        cursor: "default",
                      }}
                    >
                      설정
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>

          <Modals
            btnvalue="게시글 작성"
            cls="btn-success"
            parenfunc={(e) => {
              setkey(key + 1);
            }}
          />
        </div>
        <div className="b-divider b-vr"></div>
        <div
          className="leftbar p-3 link-body-emphasis right_container"
          style={{ width: 80 + `vw`, height: 100 + `vh`, overflow: "auto" }}
        >
          <RightPage type={pageType} />
        </div>
      </main>
    </div>
  );
}

export default Admin;
